<template>
  <div class="partnerlInks">
    <b-card title="Search Partners">
      <b-row>
        <b-col cols="12" lg="3">
          <label for="">Search</label>
          <b-form-input v-model="searchQuery" placeholder="Search By Name / EMail" />
        </b-col>
        <b-col cols="12" lg="3">
          <label for="country">Nationality</label>
          <v-select :options="countries" v-model="country" @search="searchCountries" />
        </b-col>
        <b-col cols="12" lg="3">
          <label for="status">Status</label>
          <v-select :options="statuses" v-model="isActive" />
        </b-col>
        <b-col cols="12" lg="3">
          <label for="partnerType">Partner Type</label>
          <v-select :options="partnerTypes" v-model="partnerType" />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        show-empty
        :empty-html="isLoading ? 'Loading...' : 'Record not found'"
        :items="loadPartners"
        ref="refPartnersTable"
        :fields="tableFields"
      >
        <template #cell(name)="data">{{ data.item.partner.fullName }}</template>
        <template #cell(email)="data">{{ data.item.partner.emailAddress }}</template>
        <template #cell(nationality)="data"> {{ data.item.partner.nationality.name }}</template>
        <template #cell(partnerType)="data">{{ data.item.partner.partnerType }}</template>
        <template #cell(rating)="data"
          ><feather-icon icon="StarIcon" /> {{ data.item.ratingNumber.toFixed(2) }}</template
        >
        <template #cell(actions)="data">
          <b-link size="sm" :to="`partners/view/${data.item._id}`" variant="primary">View</b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { BLink, BRow, BCol, BCard, BButton, BTable, BFormInput } from "bootstrap-vue";
import vSelect from "vue-select";
import usePartners from "./usePartners";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BTable,
    vSelect,
  },

  setup() {
    const {
      tableFields,
      partnerTypes,
      countries,
      searchCountries,
      searchQuery,
      country,
      isActive,
      statuses,
      perPageOptions,
      perPage,
      partnerType,
      refPartnersTable,
      loadPartners,
      isLoading,
    } = usePartners();

    return {
      tableFields,
      partnerTypes,
      countries,
      searchCountries,
      searchQuery,
      country,
      isActive,
      statuses,
      perPageOptions,
      perPage,
      partnerType,
      refPartnersTable,
      loadPartners,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
