import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function usePartners() {
  const toast = useToast();

  const refPartnersTable = ref(null);
  const isLoading = ref(false);

  const tableFields = [
    { key: "name" },
    { key: "email" },
    { key: "nationality" },
    { key: "partnerType" },
    { key: "rating" },
    { key: "actions" },
  ];

  const searchQuery = ref("");
  const country = ref("");
  const partnerType = ref("");
  const isActive = ref("");

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);

  /* 
	Filters Predefined Data
	*/
  const countries = ref([]);
  const partnerTypes = [
    { key: "company", label: "Company" },
    { key: "individual", label: "Individual" },
  ];
  const statuses = [
    {
      key: "1",
      label: "Active",
    },
    {
      key: "0",
      label: "Inactive",
    },
  ];

  const searchCountries = async (search, loading) => {
    try {
      const formData = new FormData();
      formData.append("q", search);
      formData.append("perPage", 10);
      const res = await axios.post("countries/search", formData);
      const items = [];
      if (res.data.data.length) {
        res.data.data.map((x) => {
          items.push({
            label: `${x.name} - ${x.code}`,
            value: x._id,
          });
        });
        countries.value = items;
      }
    } catch (error) {}
  };

  const loadPartners = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("nationality", country.value ? country.value.value : "");
      formData.append("isActive", isActive.value.value || "");
      formData.append("partnerType", partnerType.value.value || "");
      const response = await axios.post("partners/search", formData);
      return response.data.data;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  };

  /// lets see if the filter is changed
  watch([searchQuery, country, isActive, partnerType, perPage], () => {
    refPartnersTable.value.refresh();
  });

  return {
    loadPartners,
    tableFields,
    partnerTypes,
    countries,
    searchCountries,
    searchQuery,
    country,
    partnerType,
    isActive,
    statuses,
    perPageOptions,
    perPage,
    refPartnersTable,
    isLoading,
  };
}
